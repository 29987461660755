import { Fragment } from 'react'
import Header from './components/Header'
import Footer from './components/Footer'
import Layout1 from './components/layout/Layout1';
import Layout2 from './components/layout/Layout2';
import Home from './pages/Home';
import Contact from './pages/Contact'
import About from './pages/About'
import Blog from './pages/blog/Blog'
import ScrollToTop from './components/ScrollToTop';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Fragment>
      <Router>
      
     <Header/>
     <ScrollToTop/>
        
         
          <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/about" component={About} />
          <Route exact path="/blog" component={Blog} />
          
          
            </Switch>
          
       
        
        <Footer />
      </Router>
    </Fragment>
  );
}

export default App;
