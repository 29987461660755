import React from 'react'
import '../assets/css/about.css'
const About = () => {
    return (
        <div className='inner-wrapper'>
            <div className='inner-banner about-banner'>
                <div className='container'>
                    <div className='row al-center'>
                        <div className='col-7 col-md-12'>
                            <div className='banner-content-abt'>
                                <div className='bnr-abt-small-title'>
                                    <p><span></span> About Us </p>
                                </div>
                                <div className='bnr-abt-big-title'>
                                    <h1><span>Maxx Wellness</span> is a
                                        One-stop platform for
                                        holistic development for the/your entire family.</h1>
                                </div>
                                <div className='bnr-abt-text'>
                                    <p>We provide life transforming fitness programs for everyone.</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-5 pd-left0 col-md-12'>
                            <img src={process.env.PUBLIC_URL + "/images/about/banner-main.png"} alt="kid" className='bnr-rt-img' />
                        </div>
                    </div>
                </div>
            </div>
            <div className='abt-our-story'>
                <div className='border-top-abt'>
                    <div className='db-top-abt-left'>
                    </div>
                    <div className='db-top-abt-right'>
                    </div>
                </div>
                <div className='abt-left-dg'>
                   <img src={process.env.PUBLIC_URL + "/images/about/dsg-left.png"} alt="kid"  />
                </div>
                <div className='abt-right-dg'>
                   <img src={process.env.PUBLIC_URL + "/images/about/dsg-right.png"} alt="kid"  />
                </div>
                <div className='abt-content-wrap'>
                <div className='abt-story-content'>
                    <div className='abt-story-content-subtitle'>
                      <p><span></span>Our story</p>
                    </div>
                    <h1>A HOLISTIC 
APPROACH TO 
HEALTH & 
PERFORMANCE</h1>
<p>Maxx Wellness weaves together distinctly different services 
that inspire people to live healthier, happier and add value 
to their lives. Maxx believes in creating unique experiences 
for its clients by providing fitness, wellness & lifestyle solutions.</p>

<p>Maxx also provides a wide range of co-curricular activities 
for Kids! Our programs are designed to ensure your child 
gets an overall development with physical & mental fitness.
</p>
                </div>
                </div>
             
            </div>
            <div className="our-mission">
                <div className='mission-section-1'>
                   <div className='mission-content'>
                       <h1>Our Mission</h1>
                       <p>Is to help families come together with the help of fitness training, happy lifestyle and a ton of co-curricular activities. We aim towards providing you and your family with the support you need to have a fun time together as a close-knit unit.</p>
                   </div>

                </div>
                <div className='mission-section-2'>
                <div className='mission-content'>
                       <h1>Our Vision</h1>
                       <p>Is to be India's No.1 Family Wellness Company and One-Stop-Shop for
Health and Wellness
programs for adults and kids, including after school activity programs.
We aim to be the most
preferred partner for Business Corporations, establishments, enterprises,
firms, institutions and
syndicates for our services.
</p>
                   </div>
                </div>
            </div>
            <div className='kid_section'>
                <div className='container'>
                    <div className='kid_image'>
                        <img src={process.env.PUBLIC_URL + "/images/kid.png"} alt="kid" className='img-resp' />
                    </div>
                </div>

            </div>
        </div>
    )
}

export default About