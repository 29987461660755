import React,{useState, useEffect} from 'react'
import { Link, useLocation } from 'react-router-dom'

const Header = () => {
   const location = useLocation();
  const [isOpen, setOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
 useEffect(() => {
   window.addEventListener("scroll", () => {
     setScroll(window.scrollY > 50);
   });
 }, []);
  return (
    <header className={location.pathname==='/blog' ? scroll ? 'bt-1 header-bs' : 'bt-1': scroll ? 'header-bs' : ''}>
        <div className='container'>
           <div className='brand'>
           <Link to="/" >
           <img src={process.env.PUBLIC_URL + '/images/logo.png'}  alt="brand"/>
           </Link>
           
           </div>
           <div className="navebar">
             <div className={isOpen ? "menu1 open" : "menu1"}>
                <ul className="nav">
           <li ><Link to="/about" className={location.pathname === '/about' ? 'active' : ''} onClick={()=>setOpen(!isOpen)}>About Us</Link></li>
           <li ><Link to="/contact" className={location.pathname === '/contact' ? 'active' : ''} onClick={()=>setOpen(!isOpen)}>Contact Us</Link></li>
           <li ><Link to="/blog" className={location.pathname === '/blog' ? 'active' : ''} onClick={()=>setOpen(!isOpen)}>Blog</Link></li>
           <li className='dropdown'><Link to="/" >Our Programs</Link>
               <ul className='dropdown-list'>
               <li ><Link to="/" onClick={()=>setOpen(!isOpen)}>Superkid</Link></li>
               <li ><Link to="/" onClick={()=>setOpen(!isOpen)}>Make Me fit</Link></li>
               <li ><Link to="/" onClick={()=>setOpen(!isOpen)}>Supermom</Link></li>
             </ul>
           </li>

           </ul>
             </div>
          
           </div>
            
        </div>
  
           <button className={isOpen ? "menubutton opened" : "menubutton"} onClick={()=>setOpen(!isOpen)}> 
            <span></span>
            <span></span>
            <span></span>
          </button>
 
    </header>
  )
}

export default Header