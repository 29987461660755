import React from 'react'
import FeaturedPostSlider from './FeaturedPostSlider';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const data = [
  {
    id: 1,
    title: "Meal planning: 100% Nutrition, 0% Efforts.",
    auther: "by Khushbu Mehta in Health Tips",
    date: "January 21, 2021",
    excerpt: "What do I serve today…again! This question arises in every household. We often ignore to plan about our meals and end up ordering food from outside. Just like, ‘Planning’ is the Key to Success and Happiness in Life. Meal planning is the key to Eating Healthy. It will help to change your eating Habits and […]",
    thumb_image: "/images/blog/thumb.png"
  },
  {
    id: 2,
    title: "Meal planning: 100% Nutrition, 0% Efforts1.",
    auther: "by Khushbu Mehta in Health Tips",
    date: "January 21, 2021",
    excerpt: "What do I serve today…again! This question arises in every household. We often ignore to plan about our meals and end up ordering food from outside. Just like, ‘Planning’ is the Key to Success and Happiness in Life. Meal planning is the key to Eating Healthy. It will help to change your eating Habits and […]",
    thumb_image: "/images/blog/thumb.png"
  }, {
    id: 3,
    title: "Meal planning: 100% Nutrition, 0% Efforts.",
    auther: "by Khushbu Mehta in Health Tips",
    date: "January 21, 2021",
    excerpt: "What do I serve today…again! This question arises in every household. We often ignore to plan about our meals and end up ordering food from outside. Just like, ‘Planning’ is the Key to Success and Happiness in Life. Meal planning is the key to Eating Healthy. It will help to change your eating Habits and […]",
    thumb_image: "/images/blog/thumb.png"
  }, {
    id: 4,
    title: "Meal planning: 100% Nutrition, 0% Efforts.",
    auther: "by Khushbu Mehta in Health Tips",
    date: "January 21, 2021",
    excerpt: "What do I serve today…again! This question arises in every household. We often ignore to plan about our meals and end up ordering food from outside. Just like, ‘Planning’ is the Key to Success and Happiness in Life. Meal planning is the key to Eating Healthy. It will help to change your eating Habits and […]",
    thumb_image: "/images/blog/thumb.png"
  }
];

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const Blog = () => {

  return (
    <div className='inner-wrapper'>
      <div className='inner-banner bg-white'>
        <div className='container'>

          <div className='title text-center'>
            <h1><span className='color-1'>B</span><span className='color-2'>L</span><span className='color-3'>O</span><span className='color-4'>G</span></h1>
          </div>


        </div>
      </div>
      <div className='inner-page-content pt-0'>
        <div className='container'>
          <div className='featured-post'>
            <FeaturedPostSlider items={data} />
          </div>

          <div className='more-post'>
            <div className='blog-title'>
              <h1>More Blogs</h1>

            </div>
            <Carousel responsive={responsive}>
                <div className='blog-item'>
                <img src="/images/blog/blog-1.png" alt="img1" className='image' />
                 <div className='more-blog-title'>
                   <p className='bd'>Staying Fit at Home</p>
                  
                   <p className='ft-12'>by Khushbu Mehta in Health Tips</p>
                   <p className='ft-12'>January 21, 2021</p>
                 </div>
                </div>
              <div className='blog-item'>
                <img src="/images/blog/blog-2.png" alt="img1" className='image' />
              <div className='more-blog-title'>
              <p className='bd'>Staying Fit at Home</p>
              <p className='ft-12'>by Khushbu Mehta in Health Tips</p>
                   <p className='ft-12'>January 21, 2021</p>
                 </div>
              </div>
              <div className='blog-item'><img src="/images/blog/blog-3.png" alt="img1" className='image' />
              <div className='more-blog-title'>
              <p className='bd'>Staying Fit at Home</p>
              <p className='ft-12'>by Khushbu Mehta in Health Tips</p>
                   <p className='ft-12'>January 21, 2021</p>
                 </div>
              </div>
              <div className='blog-item'><img src="/images/blog/blog-1.png" alt="img1" className='image' />
              <div className='more-blog-title'>
              <p className='bd'>Staying Fit at Home</p>
              <p className='ft-12'>by Khushbu Mehta in Health Tips</p>
                   <p className='ft-12'>January 21, 2021</p>
                 </div>
              </div>
              <div className='blog-item'><img src="/images/blog/blog-2.png" alt="img1" className='image' />
              <div className='more-blog-title'>
              <p className='bd'>Staying Fit at Home</p>
              <p className='ft-12'>by Khushbu Mehta in Health Tips</p>
                   <p className='ft-12'>January 21, 2021</p>
                 </div>
              </div>
              <div className='blog-item'><img src="/images/blog/blog-3.png" alt="img1" className='image' />
              <div className='more-blog-title'>
              <p className='bd'>Staying Fit at Home</p>
                   <p className='ft-12'>by Khushbu Mehta in Health Tips</p>
                   <p className='ft-12'>January 21, 2021</p>
                 </div>
              </div>
            </Carousel>
          </div>
        </div>

      </div>
      <div className='kid_section'>
        <div className='container'>
          <div className='kid_image'>
            <img src={process.env.PUBLIC_URL + "/images/kid.png"} alt="kid" className='img-resp' />
          </div>
        </div>

      </div>
    </div>
  )
}

export default Blog